:is(.containerDynamicFilters, .dialogChangeDynamicFilters, .dialogSaveDynamicFilters) .MuiFormLabel-root {
  top: 1px;
}

:is(.containerDynamicFilters, .dialogChangeDynamicFilters, .dialogSaveDynamicFilters) .MuiFormLabel-root:not(.Mui-focused, [data-shrink="true"]) {
  font-size: .9em;
  transform: translate(14px, 9px)scale(1);
}

:is(.containerDynamicFilters, .dialogChangeDynamicFilters, .dialogSaveDynamicFilters) .MuiInputBase-input:not(.MuiAutocomplete-input, .MuiSelect-select) {
  height: 40px;
  padding: 0 14px;
  font-size: .9em;
}

:is(.containerDynamicFilters, .dialogChangeDynamicFilters, .dialogSaveDynamicFilters) .MuiSelect-select {
  padding: 8.5px 14px;
  font-size: .9em;
}

:is(.containerDynamicFilters, .dialogChangeDynamicFilters, .dialogSaveDynamicFilters) .MuiFormControlLabel-root .MuiTypography-root {
  font-size: .9rem;
}

:is(.containerDynamicFilters, .dialogChangeDynamicFilters, .dialogSaveDynamicFilters) .MuiInputAdornment-root .MuiSvgIcon-root {
  width: .8em;
  height: .8em;
}

:is(.containerDynamicFilters, .dialogChangeDynamicFilters, .dialogSaveDynamicFilters) .MuiAutocomplete-root .MuiInputBase-root {
  min-height: 40px;
  padding: 5px 65px 5px 5px;
}

:is(.containerDynamicFilters, .dialogChangeDynamicFilters, .dialogSaveDynamicFilters) .MuiAutocomplete-root .MuiInputBase-root .MuiInputBase-input {
  padding: 0 0 0 5px;
  font-size: .9em;
}

:is(.containerDynamicFilters, .dialogChangeDynamicFilters, .dialogSaveDynamicFilters) .MuiAutocomplete-root .MuiChip-root {
  height: 20px;
  font-size: .8rem;
}

:is(.containerDynamicFilters, .dialogChangeDynamicFilters, .dialogSaveDynamicFilters) .MuiAutocomplete-root .MuiChip-root .MuiSvgIcon-root {
  width: .65em;
  height: .65em;
}

#formDynamicFilters-listbox {
  font-size: .9rem;
}

#formDynamicFilters-listbox li, #formDynamicFilters-listbox li div {
  min-height: 40px;
  padding-block: 0;
}

[aria-labelledby="simple-select-standard-label"] .MuiButtonBase-root {
  min-height: 40px;
  font-size: .9rem;
}

.corporate-portal-modal.MuiDialog-root[variant="dark"] .MuiBackdrop-root {
  background: none;
}

.corporate-portal-modal.MuiDialog-root[variant="dark"] .MuiDialog-paper {
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  background: #00000080;
}

.corporate-portal-modal.MuiDialog-root[variant="dark"] .MuiDialogTitle-root, .corporate-portal-modal.MuiDialog-root[variant="dark"] .MuiDialogContent-root, .corporate-portal-modal.MuiDialog-root[variant="dark"] .MuiIconButton-root {
  color: #fff;
}

.MuiOutlinedInput-root.Mui-disabled {
  opacity: 1;
  background: #f3f3f385;
  -webkit-text-fill-color: inherit !important;
}

.MuiInputBase-input.Mui-disabled {
  color: #000000de;
  -webkit-text-fill-color: inherit !important;
}

.MuiInputLabel-root {
  padding-right: 25px !important;
}

.MuiInputLabel-root.MuiInputLabel-shrink {
  padding-right: 0 !important;
}

.chart-tooltip-root {
  max-width: 60vw;
  max-height: 90vh;
  overflow: hidden;
}

.chart-tooltip-row * {
  font-size: 12px !important;
}

.chart-pie-legend-series {
  text-overflow: ellipsis;
  max-width: 300px;
  overflow: hidden;
}

.css-uguv5e-MuiResponsiveChart-container {
  max-width: 100%;
}

.form-input-radio--columns {
  flex-direction: row !important;
}

.form-input-radio--columns .form-input-radio__label {
  width: 49%;
}

@media (max-width: 1050px) {
  .form-input-radio--columns .form-input-radio__label {
    width: 100%;
  }
}

.form-input-multi-checkbox--columns {
  flex-wrap: wrap;
  flex-direction: row !important;
}

.form-input-multi-checkbox--columns .form-input-multi-checkbox__item {
  width: 49%;
}

@media (max-width: 1050px) {
  .form-input-multi-checkbox--columns .form-input-multi-checkbox__item {
    width: 100%;
  }
}

.simple-drop-down-menu .MuiPaper-root {
  margin-top: 10px;
}

.simple-drop-down-menu .MuiList-root > .MuiButtonBase-root {
  cursor: auto;
}

.simple-drop-down-menu .MuiList-root > .MuiButtonBase-root:hover, .simple-drop-down-menu .MuiList-root > .MuiButtonBase-root.Mui-focusVisible {
  background-color: #0000;
}

.form-input-control-textarea, .form-input-control-textarea-max {
  width: 100%;
}

/*# sourceMappingURL=index.d77f7d67.css.map */
